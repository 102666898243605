import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import { AiFillThunderbolt } from "react-icons/ai";
import pic from "./MyPic.jpg";
import Button from "react-bootstrap/Button";
import pdf from "../../assets/../assets/Sourav-Chaudhary-Resume.pdf";
import { ImDownload } from "react-icons/im";
import "../../../src/style.css";
import { FaRegHandPointRight } from "react-icons/fa";
import Particle from "../Particle";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaGraduationCap } from "react-icons/fa";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { AiFillStar } from "react-icons/ai";
import { FaLink } from 'react-icons/fa';
import { bgAbout } from "../../encodedImages/bg7";
import educationLogo from '../../components/EducationLogo.png'
import heroImg from '../../assets/img/hero-img.png'


function Home2() {
  useEffect(() => {
    AOS.init({ delay: 50 });
  }, []);


  const style = {
    backgroundImage: `var(--image-gradient), url(${bgAbout})`,
  };


  return (
    <Container fluid className="home-about-section" id="about"
      style={style}
    >
      <Particle />
      <h1 data-aos="zoom-in-down">
        {" "}
        <strong style={{ color: "#f4511e" }}>About</strong> Me
      </h1>
      <h1 style={{ color: "white" }} data-aos="zoom-in-right">
        An Individual with
        <strong className="purple"> endless </strong>
        ambitions.
      </h1>
      <Row>
        <Col md={4} className="myAvtar" data-aos="zoom-in-up">

          <img
            src={pic}
            style={{
              borderRadius: "60%",
              boxShadow:
                "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
              width: "280px",
            }}
            className="img-fluid home-img"
            alt="avatar"
          />
          {/* </Tilt> */}
        </Col>
        <Col
          className="home-about-description"

        // data-aos="zoom-in-left"
        >
          <div className="home-about-body">
            <ul style={{ paddingTop: "25px" }}>
              <p
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-bottom"
              >
                &nbsp; I successfully graduated in{" "}
                <span
                  style={{
                    color: "#f4511e",
                    fontSize: "20px",
                    fontWeight: "800",
                  }}
                >
                  {" "}
                  2018{" "}
                </span>{" "}
                from Galgotias University, earning a Bachelor of Technology
                degree in{" "}
                <span
                  style={{
                    color: "#f4511e",
                    fontSize: "20px",
                    fontWeight: "800",
                  }}
                >
                  {" "}
                  Mechanical{" "}
                </span>
                Engineering, achieving first position in merit.
              </p>
              <p
              // data-aos="fade-up"
              // data-aos-duration="2000"
              // data-aos-anchor-placement="top-bottom"
              >
                {" "}
                <AiFillThunderbolt color="#FFD700" />
                &nbsp; I am currently working as Jr. Software Engineer at{" "}
                <div
                  style={{
                    color: "#f4511e",
                    fontSize: "20px",
                    fontWeight: "800",
                  }}
                >
                  {" "}

                  <div
                    style={{
                      color: "#f4511e",
                      fontSize: "20px",
                      fontWeight: "800",
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}

                    <a
                      href="https://www.headsupcorporation.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "#f4511e" }}
                    >
                      Headsup Corporation
                      <FaLink
                        style={{ marginLeft: '5px', marginRight: "5px", fontSize: "16px", color: "#FFD700" }}
                      />
                    </a>
                    {" "}
                  </div>
                  {" "}
                </div>{" "}

              </p>

              <p
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-bottom"
              >
                {" "}
                <AiFillThunderbolt color="#FFD700" />
                &nbsp; I possess over{" "}
                <span
                  style={{
                    color: "#f4511e",
                    fontSize: "20px",
                    fontWeight: "800",
                  }}
                >
                  {" "}
                  2160+{" "}
                </span>{" "}
                hours of coding experience and more than{" "}
                <span
                  style={{
                    color: "#f4511e",
                    fontSize: "20px",
                    fontWeight: "800",
                  }}
                >
                  {" "}
                  300+{" "}
                </span>{" "}
                hours of experience specifically focused on data structures and
                algorithms.
              </p>
              <p
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-anchor-placement="top-bottom"
              >
                {" "}
                <AiFillThunderbolt color="#FFD700" />
                &nbsp; I embrace{" "}
                <span
                  style={{
                    color: "#f4511e",
                    fontSize: "20px",
                    fontWeight: "800",
                  }}
                >
                  {" "}
                  challenges{" "}
                </span>{" "}
                and see them as opportunities for personal and professional
                development.
              </p>
              <p data-aos="fade-up" data-aos-duration="2000">
                {" "}
                <AiFillThunderbolt color="#FFD700" />
                &nbsp; I am not afraid to step out of my comfort zone and take
                on new and complex projects. This mindset pushes me to explore
                innovative approaches and think outside the box when{" "}
                <span
                  style={{
                    color: "#f4511e",
                    fontSize: "20px",
                    fontWeight: "800",
                  }}
                >
                  {" "}
                  problem - solving{" "}
                </span>{" "}
              </p>
            </ul>
          </div>
        </Col>
        <div className="home-about-body1 animate__animated  animate__pulse">
          <p
          // data-aos="fade-up"
          // data-aos-duration="2000"

          >
            <FaRegHandPointRight color={"FFD700"} />
            &nbsp;&nbsp; In{" "}
            <span
              style={{
                color: "#f4511e",
                fontSize: "20px",
                fontWeight: "800",
              }}
            >
              {" "}
              SUMMARY{" "}
            </span>{" "}


            . My tenure at Headsup Corporation has afforded me valuable insights into the industry, leading to the expansion of my skill set across a diverse range of technologies

            In my current capacity, I specialize in Linux, virtualization, and cloud applications, actively contributing to the development and maintenance of robust, scalable solutions. Proficient in technologies such as HTML, CSS, JavaScript React.js, Next.js, Node.js MongoDB, Mongoose, Express.js, Redux, GraphQL, Tailwind CSS and React Native, I bring a comprehensive understanding to the development landscape. 📚👨‍💻

            My commitment to continuous learning is resolute, and I strive to stay ahead with the latest trends and technologies within this dynamic field. Adept at thriving in collaborative, cross-functional environments, I excel in delivering high-quality solutions. My professional journey has reinforced my ability to produce clean, well-organized code that aligns with industry best practices. 💻✨



            {/* As a passionate and dedicated software developer, I bring a
            combination of technical expertise, collaborative spirit, attention
            to detail, and a relentless pursuit of learning. I am committed to
            creating impactful software solutions and delivering exceptional
            results in the ever-evolving world of web development. */}
          </p>
        </div>
      </Row>

      {/* resume */}

      <Row
        id="resume-button-1"
        // style={{display: "flex", flexDirection:'row'}}
        style={{
          leftContent: "center",
          position: "relative",
        }}
      >



        <a id="resume-link-1" href={pdf} download="Sourav-Chaudhary-Resume">
          <Button
            // className="wave1"
            id="resume-button-2"
            style={{
              maxWidth: "250px",
              boxShadow:
                "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
            }}
          >
            <ImDownload style={{ fontSize: "30px" }} />
            &nbsp; Download Resume
          </Button>
        </a>

      </Row>

      <div
        data-aos="zoom-in-up"
        data-aos-duration="3000"
        className="education"
        id="resume"
        style={{ backgroundColor: "", marginTop: "50px" }}
      >
        <strong>
          <h1 className="project-heading">
            <span style={{ color: "#ff6f00" }}>Ed</span>
            ucation{" "}
          </h1>
        </strong>


        {/* Vertical Education */}

        <div className="verticalEducation">

          {/* <img src={educationLogo} style={{ width: '150px', height: '150px' }} />*/}


          <div style={{marginTop: "20px", marginBottom:"10px"}} >

            <img src={heroImg} style={{ width: '150px', height: '150px' }} />
          </div>


          <h4
            data-aos="fade-right"
            style={{ color: "white", marginTop: "50px", fontWeight: 'bolder' }}
          >
            My   <span style={{ color: "#ff6f00" }}>Timeline</span>  💫
          </h4>

          <div
            style={{ marginLeft: '10px' }}

          >


            <VerticalTimeline lineColor="#C5C6D0">

              <VerticalTimelineElement
                date={"November 2022 - July 2023"}
                contentStyle={{
                  // boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;`,
                  boxShadow: "rgba(149, 157, 165, 0.9) 0px 5px 5px 5px",
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  textAlign: "left",
                  color: "#ff6f00",
                  padding: '25px',

                }}
                contentArrowStyle={{
                  borderRight: "16px solid  #ff6f00",
                  marginLeft: '10px'
                }}
                iconStyle={{
                  backgroundColor: `white`,
                  color: `#212121`,
                }}
                icon={<FaGraduationCap />}
              >



                <h3
                  className="vertical-timeline-element-title"
                  data-aos="fade-right"
                >
                  Full Stack Web Development
                </h3>
                <h5
                  className="vertical-timeline-element-subtitle"
                  data-aos="fade-right"
                >
                  Masai School (Remote)
                </h5>
                <p data-aos="fade-right" style={{
                  backgroundColor: `#C5C6D0`,
                  // backgroundColor: `#f2f3f4`,
                  color: `black`,
                  padding: '15px'
                }} >
                  The Bootcamp provided a unique environment that challenged me
                  both mentally and physically, fostering discipline, resilience,
                  and effective problem-solving abilities. Through various
                  training exercises and real-world projects, I honed my teamwork,
                  communication, and time management skills, as collaboration and
                  efficient project delivery were crucial aspects of the program.
                </p>
              </VerticalTimelineElement>



              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date={"October 2014 - December 2018"}
                contentStyle={{
                  // boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;`,
                  boxShadow: "rgba(149, 157, 165, 0.9) 0px 5px 5px 5px",
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  textAlign: "left",
                  color: "#ff6f00",
                  padding: '25px',

                }}
                contentArrowStyle={{
                  borderRight: "16px solid  #ff6f00",
                  marginLeft: '10px'
                }}
                iconStyle={{
                  backgroundColor: `white`,
                  color: `#212121`,
                }}
                icon={<FaGraduationCap />}
              >
                <h3
                  className="vertical-timeline-element-title"
                  data-aos="fade-right"
                >
                  B.Tech in Mechanical Engineering
                </h3>
                <h5 data-aos="fade-right">Galgotias University, Gr. Noida</h5>
                <p
                  className="vertical-timeline-element-subtitle"
                  data-aos="fade-right"

                  style={{
                    backgroundColor: `#C5C6D0`,
                    color: `black`,
                    padding: '15px'
                  }}
                >
                  With a bachelor's degree in mechanical engineering, I possess a
                  strong foundation in fundamental principles and practical
                  skills. My coursework covered thermodynamics, fluid mechanics,
                  mechanics of materials, dynamics and control, machine design,
                  and manufacturing processes. Through hands-on projects, I gained
                  experience in designing innovative solutions.
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date={"June 2014"}
                contentStyle={{
                  // boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;`,
                  boxShadow: "rgba(149, 157, 165, 0.9) 0px 5px 5px 5px",
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  textAlign: "left",
                  color: "#ff6f00",
                  padding: '25px',

                }}
                contentArrowStyle={{
                  borderRight: "16px solid  #ff6f00",
                  marginLeft: '10px'
                }}
                iconStyle={{
                  backgroundColor: `white`,
                  color: `#212121`,

                }}
                icon={<FaGraduationCap />}
              >
                <h3
                  className="vertical-timeline-element-title"
                  data-aos="fade-right"
                >
                  Completed Higher Secondary - Non-Medical
                </h3>
                <h4
                  className="vertical-timeline-element-subtitle"
                  data-aos="fade-right"
                ></h4>
                <p data-aos="fade-right" style={{
                  backgroundColor: `#C5C6D0`,
                  color: `black`,
                  padding: '15px'
                }} >
                  Convent of Gagan Bharti School, New Delhi
                </p>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                icon={<AiFillStar />}
                iconStyle={{
                  // backgroundColor: `rgb(124, 179, 66)`,
                  // backgroundColor: 'rgb(128, 216, 255)',
                  backgroundColor: 'rgb(144, 202, 249)',
                  color: `#212121`,


                }}
              ></VerticalTimelineElement>
            </VerticalTimeline>

          </div>
        </div>
      </div>
    </Container>
  );
}
export default Home2;
