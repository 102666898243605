import React, { useState, useEffect, useRef } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer";
import Resume from "./components/Resume/ResumeNew";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { TopButton } from "./components/TopButton";
import Home2 from "./components/Home/Home2";
import Contact from "./components/contact/contact";
import IndividualProjectPage from "./components/Projects/IndividualProjectPage";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  const [scroll, setScroll] = useState(0);
  const [load, upadateLoad] = useState(true);

  const onScroll = () => {
    const scrolled = document.documentElement.scrollTop;
    const maxHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrollPercent = (scrolled / maxHeight) * 100;
    setScroll(scrollPercent);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    window.addEventListener("scroll", onScroll);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Preloader load={load} />
      <Navbar />

      <div className="scroll-container">
        <div>
          <div className="scroll-indicator" style={{ width: `${scroll}%` }}>
            <div className="remaining-scroll"></div>
          </div>
        </div>
      </div>

      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<Home2 />} />
          <Route path="/skills" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route
            path="/projects/detailed"
            element={<IndividualProjectPage />}
          />
          <Route path="/contact" element={<Contact />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>

        <TopButton />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
