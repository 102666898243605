import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiLinux,
  SiVisualstudiocode,
  SiPostman,
  SiHeroku,
  SiVercel,
  SiCypress,
  SiJest,
  SiCodesandbox,
  SiNetlify,
} from "react-icons/si";
import { DiNodejs, DiGit, DiNpm, DiCodepen } from "react-icons/di";
import AOS from "aos";
import "aos/dist/aos.css";

function Toolstack() {
  useEffect(() => {
    AOS.init({ delay: 500 });
  }, []);
  return (
    <Row
      style={{ justifyContent: "center", paddingBottom: "50px" }}
      id="skills"
      className="skills-card"
      data-aos="fade-left"
    >
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
        <h5>VS Code</h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
        <h5>GitHub </h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiCypress />
        <h5>Cypress</h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiJest />
        <h5>Jest</h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiCodesandbox />
        <h5>CodeSandbox </h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiCodepen />
        <h5>CodePen </h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiPostman />
        <h5>Postman </h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiHeroku />
        <h5>Heroku </h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiNetlify />
        <h5>Netlify </h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiVercel />
        <h5>Vercel </h5>
      </Col>
    </Row>
  );
}

export default Toolstack;
