import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn, AiOutlineMail } from "react-icons/fa";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { codeAwesomeGif } from "../encodedImages/codeawesome";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    // <Container fluid className="footer">
    //   <Row>
    //     <Col md="4" className="footer-copywright">
    //       <h3>Designed and Developed by Sourav Chaudhary with ❤️</h3>
    //     </Col>
    //     <Col md="4" className="footer-copywright">
    //       <h3>Thank you for visiting my website.</h3>
    //       <br></br>
    //       <h3>Copyright © {year} </h3>
    //     </Col>



    //     <Col className="footer-body">

    //       <img
    //         style={{
    //           width: '20%',
    //         }}

    //         src={codeAwesomeGif} alt="Your GIF" />


    //     </Col>
    //     <Col md="4" className="footer-body">
    //       <ul className="home-about-social-links">
    //         <li className="social-icons">
    //           <a
    //             id="contact-github"
    //             href="https://github.com/Sourav01112"
    //             target="_blank"
    //             rel="noreferrer"
    //             className="icon-colour  home-social-icons"
    //           >
    //             <AiFillGithub />
    //           </a>
    //         </li>

    //         <li className="social-icons">
    //           <a
    //             id="contact-linkedin"
    //             href="https://www.linkedin.com/in/sourav-chaudhary-214609155/"
    //             target="_blank"
    //             rel="noreferrer"
    //             className="icon-colour  home-social-icons"
    //           >
    //             <FaLinkedinIn />
    //           </a>
    //         </li>
    //         <li className="social-icons">
    //           <a
    //             id="contact-email"
    //             href="mailto:chaudharysourav.vats@gmail.com "
    //             target="_blank"
    //             rel="noreferrer"
    //             className="icon-colour home-social-icons"
    //           >
    //             <BsFillEnvelopeFill />
    //           </a>
    //         </li>
    //       </ul>
    //     </Col>
    //   </Row>
    // </Container>


    <Container fluid className="footer">
      <Row>
        <Col sm='4' className="footer-copywright d-flex justify-content-center">
          <div>
            <h3>Designed and Developed by Sourav Chaudhary with ❤️</h3>
          </div>
        </Col>
        <Col md="4" className="footer-copywright">
          <h3>Thank you for visiting my website.</h3>
          <h3>Copyright © {year} </h3>
        </Col>

        <Col sm='4' className="footer-body d-flex justify-content-center">
          <img
            style={{
              width: '20%',
            }}
            className="mx-auto"
            src={codeAwesomeGif} alt="Your GIF"
          />
        </Col>



      </Row>


      <Col className="footer-body d-flex justify-content-center">
        <ul className="home-about-social-links">
          <li className="social-icons">
            <a
              id="contact-github"
              href="https://github.com/Sourav01112"
              target="_blank"
              rel="noreferrer"
              className="icon-colour  home-social-icons"
            >
              <AiFillGithub />
            </a>
          </li>
          <li className="social-icons">
            <a
              id="contact-linkedin"
              href="https://www.linkedin.com/in/sourav-chaudhary-214609155/"
              target="_blank"
              rel="noreferrer"
              className="icon-colour  home-social-icons"
            >
              <FaLinkedinIn />
            </a>
          </li>
          <li className="social-icons">
            <a
              id="contact-email"
              href="mailto:chaudharysourav.vats@gmail.com "
              target="_blank"
              rel="noreferrer"
              className="icon-colour home-social-icons"
            >
              <BsFillEnvelopeFill />
            </a>
          </li>
        </ul>
      </Col>

    </Container>


  );
}

export default Footer;


/*  <Col sm='4' className="footer-body d-flex justify-content-center">
          <ul className="home-about-social-links">
            <li className="social-icons">
              <a
                id="contact-github"
                href="https://github.com/Sourav01112"
                target="_blank"
                rel="noreferrer"
                className="icon-colour  home-social-icons"
              >
                <AiFillGithub />
              </a>
            </li>
            <li className="social-icons">
              <a
                id="contact-linkedin"
                href="https://www.linkedin.com/in/sourav-chaudhary-214609155/"
                target="_blank"
                rel="noreferrer"
                className="icon-colour  home-social-icons"
              >
                <FaLinkedinIn />
              </a>
            </li>
            <li className="social-icons">
              <a
                id="contact-email"
                href="mailto:chaudharysourav.vats@gmail.com "
                target="_blank"
                rel="noreferrer"
                className="icon-colour home-social-icons"
              >
                <BsFillEnvelopeFill />
              </a>
            </li>
          </ul>
        </Col> */