import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import pdf from "../../assets/../assets/Sourav-Chaudhary-Resume.pdf";
import { Document, Page, pdfjs } from "react-pdf";
import { ImDownload } from "react-icons/im";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
import Preloader from "../../components/Resume/PreResume";
// import Toast from "react-bootstrap-toast";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

function ResumeNew() {
  const [width, setWidth] = useState(1200);
  const [isLoading, setIsLoading] = useState(true);
  const [load, upadateLoad] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [position, setPosition] = useState("top-start");

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  const handleLoadSuccess = () => {
    setIsLoading(false);
  };

  const handleButtonClick = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };

  return (
    <div>
      <Container fluid className="resume-section">
        <Particle />
        {/* <Row style={{ justifyContent: "center", position: "relative" }}>
          <a href={pdf} download="Resume">
            <Button id="resume-button-1" style={{ maxWidth: "250px" }}>
              <AiOutlineDownload />
              &nbsp;Download Resume
            </Button>
          </a>
        </Row> */}

        <p className="noteDownload">Note: &nbsp;Download Option Below</p>

        <Row className="resume">
          <Document
            file={pdf}
            className="d-flex justify-content-center"
            onLoadSuccess={handleLoadSuccess}
          >
            <Page
              pageNumber={1}
              scale={width > 760 ? 1.7 : 0.6}
              renderTextLayer={false}
            />
          </Document>
          {isLoading && <Preloader load={load} />}
        </Row>

        {/* Resume Button */}
        <Row
          id="resume-button-1"
          style={{
            justifyContent: "center",
            position: "relative",
            // border: "1px solid white",
          }}
        >
          <a id="resume-link-1" href={pdf} download="Sourav-Chaudhary-Resume">
            <Button
              //  className="wave1"
              id="resume-button-2"
              style={{
                maxWidth: "250px",
                zIndex: 0,
                boxShadow:
                  "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
              }}
              onClick={handleButtonClick}
            >
              <ImDownload style={{ fontSize: "30px" }} />
              &nbsp; Download Resume
            </Button>
          </a>

          {/* Toast  */}
          <ToastContainer position={"top"} style={{ zIndex: 1 }}>
            <Toast
              // position={position}
              show={showToast}
              onClose={() => setShowToast(false)}
              delay={3000}
              autohide
              style={{
                // position: "absolute",
                top: 0,
                right: 0,
                backgroundColor: "#4BB543",
              }}
            >
              <Toast.Body>Downloading...</Toast.Body>
            </Toast>
          </ToastContainer>

          {/* Toast  */}
        </Row>
      </Container>
    </div>
  );
}

export default ResumeNew;
