import { Container, Row, Col } from "react-bootstrap";
import { AiFillGithub } from "react-icons/ai";
import { AiOutlineDownload } from "react-icons/ai";
import { FaLinkedinIn, AiOutlineMail } from "react-icons/fa";
import { BsMailbox2 } from "react-icons/bs";
import { BsFillEnvelopeFill, BsFillTelephoneFill } from "react-icons/bs";
import { CgPhone } from "react-icons/cg";
import { BiCurrentLocation } from "react-icons/bi";
import pdf from "../../assets/../assets/Sourav-Chaudhary-Resume.pdf";
import Button from "react-bootstrap/Button";
import { ImDownload } from "react-icons/im";
import "../../../src/style.css";
import Particle from "../Particle";
import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import contact from '../../assets/img/contact.png'

function Contact() {
  const [showToast, setShowToast] = useState(false);
  const [position, setPosition] = useState("top-start");

  useEffect(() => {
    AOS.init({ delay: 50 });
  }, []);

  const handleButtonClick = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };

  return (
    <Container id="contact" className="home-about-contact">
      <Row>
        <Col md={12} className="home-about-social">
          <h1 data-aos="zoom-in-left">
            FIND <span style={{ color: "#f4511e" }}>ME</span> HERE
          </h1>
          {/* Icons  */}
          <ul className="home-about-social-links" data-aos="zoom-in-up">
            <li className="social-icons">
              <a
                id="contact-github"
                href="https://github.com/Sourav01112"
                target="_blank"
                rel="noreferrer"
                className="icon-colour  home-social-icons"
              >
                <AiFillGithub />
              </a>
            </li>

            <li className="social-icons">
              <a
                id="contact-linkedin"
                href="https://www.linkedin.com/in/sourav-chaudhary-214609155/"
                target="_blank"
                rel="noreferrer"
                className="icon-colour  home-social-icons"
              >
                <FaLinkedinIn />
              </a>
            </li>
            <li className="social-icons">
              <a
                id="contact-email"
                href="mailto:chaudharysourav.vats@gmail.com "
                target="_blank"
                rel="noreferrer"
                className="icon-colour home-social-icons"
              >
                <BsFillEnvelopeFill />
              </a>
            </li>
          </ul>

          <hr></hr>
          <p
            style={{
              margin: "auto",
              width: "300px",
              textAlign: "justify",
              paddingTop: "15px",
              fontWeight: "800",
            }}
          >
            Feel free to <span className="purple">connect </span>with me.
          </p>
          <Row
            style={{ display: "flex", alignItems: "center", margin: "auto" }}
          >
            <BsMailbox2
              style={{
                marginRight: "10px",
                marginTop: "50px",
                fontSize: "30px",
                color: "orange",
              }}
            />
            <p data-aos="zoom-in-down" id="contact-email">
              chaudharysourav.vats@gmail.com
            </p>
          </Row>
          <BiCurrentLocation
            style={{
              marginRight: "10px",
              marginTop: "25px",
              fontSize: "30px",
              color: "orange",
            }}
          />
          <p id="contact-email">
            New Delhi <br></br> India
          </p>
          <hr></hr>
          <p id="contact-phone" data-aos="fade-right">
            Building connections and fostering social interactions play a
            crucial role in generating innovative ideas and achieving
            significant advancements. I believe in the power of socializing and
            connecting with others, as it often leads to remarkable insights and
            groundbreaking discoveries. If you wish to get in touch with me, you
            can reach out to me through the provided contact details or feel
            free to contact me directly at: <br></br>
            <span id="contact-email">
              <CgPhone style={{ color: "#76FF03", marginRight: "5px" }} />
              +91 - 9871940990
            </span>{" "}
          </p>
          <hr></hr>
          <Row
            id="resume-button-1"
            style={{
              justifyContent: "center",
              position: "relative",
              // border: "1px solid white",
            }}
          >
            <a
              id="resume-button-1"
              href={pdf}
              download="Sourav-Chaudhary-Resume"
            >
              <Button
                // className="wave1"
                id="resume-button-1"
                style={{
                  maxWidth: "250px",

                  boxShadow:
                    "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
                }}
              >
                <ImDownload style={{ fontSize: "30px" }} />
                &nbsp;Resume
              </Button>
            </a>
            {/* Toast  */}
            <ToastContainer
              position={"top"}
              style={{ zIndex: 9999, position: "absolute" }}
            >
              <Toast
                // position={position}
                show={showToast}
                onClose={() => setShowToast(false)}
                delay={3000}
                autohide
                style={{
                  // position: "absolute",
                  top: 0,
                  right: 0,
                  backgroundColor: "#4BB543",
                }}
              >
                <Toast.Body>Downloading...</Toast.Body>
              </Toast>
            </ToastContainer>

            {/* Toast  */}
          </Row>
        </Col>





     







      </Row>
      <Particle />



{/* 

      <section id="contact" className="contact-section">
          <h2 className="section-title">Get in Touch</h2>
          <p className="section-description">
            I'd love to hear from you!
          </p>

          <div className="form-container">
            <div>
              <form id="email-form" className="email-form">
                <label htmlFor="fullName" className="form-label">Full Name:</label>
                <input type="text" id="fullName" name="fullName" className="form-input" placeholder="Your Name" required />

                <label htmlFor="email" className="form-label">Email:</label>
                <input type="email" id="email" name="email" className="form-input" placeholder="Your Email ID" required />

                <label htmlFor="message" className="form-label">Message:</label>
                <textarea id="message" name="message" className="form-textarea" placeholder="Your Message..." required></textarea>

                <button type="button" id="submit-btn" className="submit-btn">Submit</button>
              </form>

            </div>

            <div
              className="image-container">
              <img
                src={contact}

                alt="contact"
                className="contact-image"
              />

            </div>


          </div>
        </section> */}


          {/* <img
            src={contactPic}
            alt="my-socials"
            className="socials-image"
          /> */}

    </Container>
  );
}

export default Contact;
