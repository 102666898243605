import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiGit,
  DiCss3,
} from "react-icons/di";
import {
  SiRedux,
  SiTypescript,
  SiChakraui,
  SiExpress,
  SiBootstrap,
  SiAntdesign,
  SiMongodb,
  SiNextdotjs,
  SiReactrouter,
} from "react-icons/si";
import { TiHtml5 } from "react-icons/ti";
import AOS from "aos";
import "aos/dist/aos.css";

function Techstack() {
  useEffect(() => {
    AOS.init({ delay: 50 });
  }, []);

  return (
    <Row
      style={{ justifyContent: "center", paddingBottom: "50px" }}
      id="skills"
      className="skills-card"
      data-aos="fade-right"
    >
      <div id="skills" className="skills-card row">
        {/* Add your desired elements and content here */}
        <img className="skills-card-img" src="" alt="" />
      </div>
      <Col xs={4} md={2} className="tech-icons">
        <TiHtml5 />
        <h5>HTML</h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiCss3 />
        <h5>CSS</h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
        <h5>JavaScript</h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
        <h5>ReactJS</h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiNextdotjs />
        <h5>NextJS (B)</h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiRedux />
        <h5>Redux</h5>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiReactrouter />
        <h5>React Router</h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiTypescript />
        <h5>TypeScript</h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
        <h5>Node.jS</h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiExpress />
        <h5>Express</h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiMongodb />
        <h5>MongoDB</h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiChakraui />
        <h5>Chakra UI</h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiBootstrap />
        <h5>Bootstrap</h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiAntdesign />
        <h5>Ant Design</h5>
      </Col>
    </Row>
  );
}

export default Techstack;
