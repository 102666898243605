import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";
import { GiLinkedRings } from "react-icons/gi";
import { SiGithubactions } from "react-icons/si";
import "../../style.css";
import Tilt from "react-parallax-tilt";

import Badge from "react-bootstrap/Badge";
import { Link } from "react-router-dom";

function ProjectCards({
  imgPath,
  title,
  description,
  tech,
  ghLink,
  isBlog,
  demoLink,
  description1,
}) {
  const handleDetailed = () => {
    // console.log(title);
    localStorage.setItem(
      "detail",
      JSON.stringify({
        imgPath,
        title,
        description,
        description1,
        tech,
        ghLink,
        isBlog,
        demoLink,
      })
    );
    // <Link to="/projects/detailed"></Link>;
  };

  return (
    <Link to="/projects/detailed" className="link-style">
      <Card className="project-card-view" onClick={handleDetailed}>
        <div className="gridContainer">
          <div
            style={{
              // border: "1px solid yellow",
              // marginTop: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Tilt>
              <Card.Img
                className="project-img"
                // style={{
                //   width: "120%",
                // }}
                variant="top"
                src={imgPath}
                alt="card-img"
              />
            </Tilt>
          </div>
          <div
            className="project-body"
            // style={{
            //   // border: "1px solid yellow",
            //   // marginLeft: '20px',
            //   padding: "15px",
            // }}
          >
            <Card.Body>
              <Card.Title className="project-title">
                {" "}
                &#8251;&nbsp; {title}
              </Card.Title>

              {/* <Card.Text
                className="project-description"
                // style={{ textAlign: "justify" }}
              >
                {description}
              </Card.Text> */}

              <Card.Text
                className="project-description"
                // style={{ textAlign: "justify" }}
              >
                {description1}
              </Card.Text>

              <h5
                style={{
                  textTransform: "uppercase",
                  fontFamily: "Dancing Script, cursive",
                  fontWeight: 700,
                  color: "#f4511e",
                }}
              >
                &#9889; Tech
              </h5>
              <Card.Text
                style={{
                  textAlign: "center",
                }}
              >
                {tech}
              </Card.Text>

              <div className="mobileBtn">
                <Button
                  // variant="primary"
                  className="btnSingle"
                  href={ghLink}
                  target="_blank"
                  style={{ backgroundColor: "yellow" }}
                >
                  Know More !
                </Button>
              </div>
            </Card.Body>
          </div>
        </div>
      </Card>
    </Link>
  );
}
export default ProjectCards;
