import React from "react";
import { useState, useEffect } from "react";
import { FaArrowCircleUp } from "react-icons/fa";
import "../style.css";
import { BiUpArrow } from "react-icons/bi";
import { IoMdArrowDropupCircle } from "react-icons/io";

export const TopButton = () => {
  //****************  Scroll to Top   **************
  const [isTop, setIsTop] = useState(false);

  //using useEffect to make sure sideEffects are being managed

  useEffect(() => {
    const handleScroll = () => {
      const threshold = document.documentElement.scrollHeight * 0.05;

      if (window.scrollY > threshold) {
        setIsTop(true);
        // it will appear when page has been scrolled more than 0.05% of total 'vh'
      } else {
        setIsTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // setIsTop(true);
  };
  //****************  Scroll to Top  ENDS **************

  return (
    <div
      className="topBtn"
      style={{
        display: isTop ? "block" : "none",
      }}
    >
      {isTop && (
        <IoMdArrowDropupCircle
          className="insidetopBtn"
          color="#DD2C00"
          onClick={scrollToTop}
        />
      )}
    </div>
  );
};
