import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Link, NavLink } from "react-router-dom";
import { CgGitFork } from "react-icons/cg";
import { ImBlog } from "react-icons/im";
import {
  AiFillStar,
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
  AiOutlineUser,
  AiOutlineCloudDownload,
} from "react-icons/ai";
import { CgFileDocument } from "react-icons/cg";
import { MdCastForEducation } from "react-icons/md";
import { ImPencil2 } from "react-icons/im";
import { RiContactsBookFill } from "react-icons/ri";

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const [active, setIsActive] = useState(false);
  const [activeLink, setActiveLink] = useState("");

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      id="nav-menu"
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        <Navbar.Brand className="d-flex">
          <Nav.Item>
            <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
              <span>{"<\u00A0SOURAV\u00A0\u00A0Codes... />"}</span>
            </Nav.Link>
          </Nav.Item>
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link
                className={`nav-link home ${activeLink === "home" ? "active" : ""
                  }`}
                as={Link}
                to="/"
                onClick={() => {
                  setActiveLink("home");
                  updateExpanded(false);
                }}
              >
                <AiOutlineHome style={{ marginBottom: "2px" }} /> Home
              </Nav.Link>
            </Nav.Item>

            {/* About */}
            <Nav.Item>
              <Nav.Link
                className={`nav-link about ${activeLink === "about" ? "active" : ""
                  }`}
                as={Link}
                to="/about"
                onClick={() => {
                  setActiveLink("about");
                  updateExpanded(false);
                }}
              >
                <AiOutlineUser style={{ marginBottom: "2px" }} /> About
              </Nav.Link>
            </Nav.Item>
            {/* Skills */}
            <Nav.Item>
              <Nav.Link
                className={`nav-link skills ${activeLink === "skills" ? "active" : ""
                  }`}
                as={Link}
                to="/skills"
                onClick={() => {
                  setActiveLink("skills");
                  updateExpanded(false);
                }}
              >
                <ImPencil2 style={{ marginBottom: "2px" }} /> Skills
              </Nav.Link>
            </Nav.Item>
            {/* Projects */}
            <Nav.Item>
              <Nav.Link
                as={Link}
                className={`nav-link projects ${activeLink === "projects" ? "active" : ""
                  }`}
                to="/projects"
                onClick={() => {
                  setActiveLink("projects");
                  updateExpanded(false);
                }}
              >
                <AiOutlineFundProjectionScreen
                  style={{ marginBottom: "2px" }}
                />{" "}
                Projects
              </Nav.Link>
            </Nav.Item>
            {/* Contact */}
            <Nav.Item>
              <Nav.Link
                as={Link}
                className={`nav-link contacts ${activeLink === "contacts" ? "active" : ""
                  }`}
                to="/contact"
                onClick={() => {
                  setActiveLink("contacts");
                  updateExpanded(false);
                }}
              >
                <RiContactsBookFill style={{ marginBottom: "2px" }} /> Contact
              </Nav.Link>
            </Nav.Item>
            {/* Resume */}


            {/* <Nav.Item>
              <Nav.Link
                className={`nav-link resume ${activeLink === "resume" ? "active" : ""
                  }`}
                as={Link}
                to="/resume"
                onClick={() => {
                  setActiveLink("resume");
                  updateExpanded(false);
                }}
              >
                <CgFileDocument style={{ marginBottom: "2px" }} /> Resume
              </Nav.Link>
            </Nav.Item> */}



          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
