import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import laptopImg from "../../assets/about.png";
import Toolstack from "./Toolstack";
import AOS from "aos";
import "aos/dist/aos.css"

function About() {
  useEffect(() => {
    AOS.init({ delay: 50 });
  }, []);


  return (
    <Container fluid className="about-section" id="about">
      <div className="about section">{/* Content goes here */}</div>
      <Particle />
      <Container id="skills">
          <h1 className="project-heading"
          data-aos="fade-up"
          
          >
            Professional <strong className="purple">SkillSet </strong>
          </h1>

          <Techstack />

        <hr></hr>
        <h1 className="project-heading">
          <strong className="purple">Tools</strong> I use
        </h1>

        <Toolstack />
        <hr></hr>
        <Github />
        <hr></hr>
      </Container>
    </Container>
  );
}

export default About;
