import React, { useEffect } from "react";

import GitHubCalendar from "react-github-calendar";
import { Row, Container } from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import "../../style.css";
import AOS from "aos";
import "aos/dist/aos.css";

function Github() {
  useEffect(() => {
    AOS.init({ delay: 50 });
  }, []);

  return (
    <Container>
      <Row
        style={{ justifyContent: "center", paddingBottom: "10px" }}
        data-aos="fade-down"
      >
        <h1 className="project-heading" style={{ paddingBottom: "20px" }}>
          Days I <strong className="purple">Coded</strong> So Far
        </h1>

        <GitHubCalendar
          username="Sourav01112"
          // hideColorLegend
          // hideTotalCount
          showWeekdayLabels
          year="last"
          blockSize={15}
          blockMargin={7}
          color="#76FF03"
          fontSize={8}
        />
      </Row>
      <hr></hr>

      <Row
        style={{
          justifyContent: "center",
          paddingBottom: "10px",
          marginTop: "20px",
        }}
      >
        <div id="stats" style={{ display: "flex", justifyContent: "center" }}>
          <a
            href="https://git.io/streak-stats"
            target="_blank"
            data-aos="fade-left"
          >
            <img
              id="github-stats-card"
              src="https://github-readme-streak-stats.herokuapp.com?user=Sourav01112&theme=vision-friendly-dark"
              alt="GitHub Streak"
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
              }}
            />
          </a>
          <a
            href="https://git.io/streak-stats"
            target="_blank"
            data-aos="fade-right"
          >
            <img
              id="github-streak-stats"
              src="https://github-readme-stats.vercel.app/api?username=Sourav01112&show_icons=true&theme=vision-friendly-dark"
              alt="GitHub Streak"
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
              }}
            />
          </a>
        </div>
      </Row>

      <Row>
        {/*  Graph */}
        <a
          href="https://git.io/streak-stats"
          target="_blank"
          data-aos="fade-left"
        >
          <img
            id="github-top-langs"
            src="https://github-readme-activity-graph.vercel.app/graph?username=sourav01112&point=ffb000&title_color=ffb000&layout=compact&height=450&radius=10&theme=high-contrast"
            alt="GitHub Streak"
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
              width: "92%",
              marginBottom: "20px",
            }}
          />
        </a>



        <a data-aos="fade-right" >
          <img
            id="github-top-langs"
            src="https://github-profile-summary-cards.vercel.app/api/cards/profile-details?username=Sourav01112&theme=dark"
            alt="GitHub Streak"
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
              width: "92%",
              marginBottom: "20px",
            }}
          />
        </a>






        {/* Most Language */}
        <a
          href="https://git.io/streak-stats"
          target="_blank"
          data-aos="fade-top"
        >
          <img
            id="github-top-langs"
            // src="https://github-readme-stats.vercel.app/api/top-langs/?username=Sourav01112&layout=compact&theme=dark"
            src="https://github-readme-stats.vercel.app/api/top-langs/?username=sourav01112&layout=compact&theme=vision-friendly-dark"
            alt="GitHub Streak"
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
            }}
          />
        </a>
      </Row>
    </Container>
  );
}

export default Github;
