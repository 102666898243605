import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { BsGithub } from "react-icons/bs";
import { GiLinkedRings } from "react-icons/gi";
import {
  SiChakraui,
  SiExpress,
  SiGithubactions,
  SiReactrouter,
  SiRedux,
  SiBootstrap,
  SiAntdesign,
  SiMongodb,
  SiNextdotjs,
  SiJsonwebtokens,
  SiTailwindcss,
  SiWebrtc,
  SiSocketdotio,
} from "react-icons/si";
import { bgProject } from '../../encodedImages/bgProject'


import "../../style.css";
import {
  DiCss3,
  DiJavascript1,
  DiNodejs,
  DiReact,
  DiMongodb,
} from "react-icons/di";
import { FaKey } from "react-icons/fa";
import { VscJson } from "react-icons/vsc";
import { TiHtml5 } from "react-icons/ti";
import { AiFillApi } from "react-icons/ai";
import { MdSdStorage } from "react-icons/md";

function IndividualProjectPage() {
  const data = JSON.parse(localStorage.getItem("detail"));
  const [project, setProject] = useState(data);

  const renderTechIcons = (tech) => {
    const technologies = tech.split(" | ");
    return technologies.map((technology) => {
      switch (technology) {
        case "Redux Thunk":
        case "Redux":
        case "Redux Toolkit":
          return (
            <div className="tech-icons1">
              <SiRedux key={technology} />
              <p>{technology}</p>
            </div>
          );
        case "React":
          return (
            <div className="tech-icons1">
              <DiReact key={technology} />
              <p>{technology}</p>
            </div>
          );
        case "React Router":
          return (
            <div className="tech-icons1">
              <SiReactrouter key={technology} />
              <p>{technology}</p>
            </div>
          );
        case "HTML":
          return (
            <div className="tech-icons1">
              <TiHtml5 key={technology} />
              <p>{technology}</p>
            </div>
          );
        case "CSS":
          return (
            <div className="tech-icons1">
              <DiCss3 key={technology} />
              <p>{technology}</p>
            </div>
          );
        case "JavaScript":
          return (
            <div className="tech-icons1">
              <DiJavascript1 key={technology} />
              <p>{technology}</p>
            </div>
          );
        case "JavaScript (Declarative - Way)":
          return (
            <div className="tech-icons1">
              <DiJavascript1 key={technology} />
              <p>{technology}</p>
            </div>
          );
        case "ChakraUI":
          return (
            <div className="tech-icons1">
              <SiChakraui key={technology} />
              <p>{technology}</p>
            </div>
          );
        case "Node":
          return (
            <div className="tech-icons1">
              <DiNodejs key={technology} />
              <p>{technology}</p>
            </div>
          );
        case "Express":
          return (
            <div className="tech-icons1">
              <SiExpress key={technology} />
              <p>{technology}</p>
            </div>
          );
        case "MongoDB":
          return (
            <div className="tech-icons1">
              <SiMongodb key={technology} />
              <p>{technology}</p>
            </div>
          );
        case "JWT":
          return (
            <div className="tech-icons1">
              <SiJsonwebtokens key={technology} />
              <p>{technology}</p>
            </div>
          );
        case "Bcrypt":
          return (
            <div className="tech-icons1">
              <FaKey key={technology} />
              <p>{technology}</p>
            </div>
          );
        case "Ant Design":
          return (
            <div className="tech-icons1">
              <SiAntdesign key={technology} />
              <p>{technology}</p>
            </div>
          );
        case "Tailwind CSS":
          return (
            <div className="tech-icons1">
              <SiTailwindcss key={technology} />
              <p>{technology}</p>
            </div>
          );
        case "Next":
          return (
            <div className="tech-icons1">
              <SiNextdotjs key={technology} />
              <p>{technology}</p>
            </div>
          );
        case "JSON Server":
          return (
            <div className="tech-icons1">
              <VscJson key={technology} />
              <p>{technology}</p>
            </div>
          );
        case "API call":
          return (
            <div className="tech-icons1">
              <AiFillApi key={technology} />
              <p>{technology}</p>
            </div>
          );
        case "Local Storage":
          return (
            <div className="tech-icons1">
              <MdSdStorage key={technology} />
              <p>{technology}</p>
            </div>
          );
        case "React Bootstrap":
          return (
            <div className="tech-icons1">
              <SiBootstrap key={technology} />
              <p>{technology}</p>
            </div>
          );
        case "WebRTC":
          return (
            <div className="tech-icons1">
              <SiWebrtc key={technology} />
              <p>{technology}</p>
            </div>
          );
        case "Socket.IO":
          return (
            <div className="tech-icons1">
              <SiSocketdotio key={technology} />
              <p>{technology}</p>
            </div>
          );

        default:
          return null;
      }
    });
  };

  const style = {
    backgroundImage: `var(--image-gradient), url(${bgProject})`,
  };



  return (
    <div className="project-section1"
    
    style={style}
    
    >
      <Card className="project-card-view1">
        <div className="gridContainer">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Card.Img
              className="project-img1"
              variant="top"
              src={project?.imgPath}
              alt="card-img"
            />

            {/*  Buttons */}

            <div className="mobileBtn">
              <Button
                variant="primary"
                href={project?.ghLink}
                target="_blank"
                className="project-github-link"
              >
                <BsGithub /> &nbsp;
                {project?.isBlog ? "Blog" : "GitHub"} &nbsp;
              </Button>
              {"\n"}
              {"\n"}

              {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}

              {!project?.isBlog && project?.demoLink && (
                <Button
                  className="project-github-link"
                  variant="primary"
                  href={project?.demoLink}
                  target="_blank"
                  style={{ marginLeft: "10px" }}
                >
                  <GiLinkedRings /> &nbsp;
                  {"Live"}
                </Button>
              )}
            </div>
          </div>
          <div className="project-body">
            <Card.Body>
              <Card.Title className="project-title">
                {" "}
                &#8251;&nbsp; {project?.title}
              </Card.Title>
              <Card.Text className="project-description">
                {project?.description}
              </Card.Text>
              {/* Detailed explantion with features and repsonsibility */}

              <h5
                style={{
                  textTransform: "uppercase",
                  fontFamily: "Dancing Script, cursive",
                  fontWeight: 700,
                  color: "#f4511e",
                }}
              >
                &#9889; Tech
              </h5>
              {/* ICONS */}
              <div className="tech-Conatiner">
                {renderTechIcons(project?.tech)}
              </div>
            </Card.Body>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default IndividualProjectPage;
