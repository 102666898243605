import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import About from "../About/About";
import Projects from "../Projects/Projects";
import bannerImage from "../../assets/mybannerImage.png";
import TrackVisibility from "react-on-screen";
import "animate.css";
import Contact from "../contact/contact";
import { Flip, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { FcOpenedFolder } from "react-icons/fc";
import { banner_bg } from '../../encodedImages/banner-bg'


function Home() {
  const location = useLocation();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  useEffect(() => {
    if (location.pathname === "/") {
      const oneTime = setTimeout(() => {
        toast.info(
          <div>
            <FcOpenedFolder className="iconToast" size={20} />
            <span className="fontToast">
              &nbsp;Welcome to my &nbsp;<span className="foo">portfolio</span>
            </span>
          </div>,
          {
            position: isMobile ? "bottom-center" : "top-right",
            autoClose: 8500,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "default",
            icon: "🚀",
            transition: Flip,
          }
        );
      }, 1000);

      // 2979ff
      // Clean up the interval when the component unmounts
      return () => {
        clearInterval(oneTime);
      };
    }
  }, [location.pathname]);

  const style = {
    backgroundImage: `var(--image-gradient), url(${banner_bg})`,
  };


  return (
    <section>
      <ToastContainer transition={Flip} />
      <Container fluid className="home-section" id="home"

        style={style}

      >
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">


              {/* <TrackVisibility>
                {({ isVisible }) => (
                  <div
                    className={
                      isVisible ? "animate__animated animate__fadeIn hello" : ""
                    }
                  >
                    <span className="rocketwave">
                      <img
                        style={{
                          width: "50px",
                          marginLeft: "30px",
                          paddingBottom: "10px",
                        }}
                        src="https://em-content.zobj.net/source/noto-emoji-animations/344/rocket_1f680.gif"
                        alt=""
                      />
                    </span>
                  </div>
                )}
              </TrackVisibility> */}

              {/*  Hi There Part and Typing */}

              <div className="bannerBox">
                <h1 style={{ paddingBottom: 15 }} className="heading animate__animated animate__zoomIn">
                  Hi There!{" "}
                  <span id="name" className="wave" role="img" aria-labelledby="wave">
                    👋
                  </span>
                </h1>




                <div className="content heading-name animate__animated animate__zoomIn" >
                  <p style={{ paddingRight: '20px' }}>I am</p>
                  <div className="text animate__animated animate__fadeInUp" 
                  data-text="Sourav Chaudhary"
                  >
                    <span id="name" >Sourav Chaudhary</span>
                  </div>
                </div>







                {/* 
                <h1 className="heading-name">
                  I'M
                  <strong className="main-name" id="user-detail-name">
                    {" "}
                    Sourav Chaudhary
                  </strong>
                </h1> */}




                <p className="phone__intro">
                  {" "}
                  A passionate full stack web developer.
                </p>
                {/* <div
                  className="Typewriter_wrapper"
                  style={{ textAlign: "left", paddingBottom: "10px" }}
                >
                  <Type className="typePad" />
                </div> */}
              </div>

              <h4 className="introFirst" id="user-detail-intro">
                I'm a full stack developer with more than 6 months of real-world experience 🚀🛠️ with proficiency in React, Next.js, Node.js, Express.js, MongoDB, and React Native.🧑‍💻
                {/* Having a strong base in HTML, CSS, JavaScript, React, and Redux
                technologies and equipped with a backend development experience
                in Node.js and MongoDB. Committed to delivering quality work to
                the organization and striving to learn new technologies to
                create responsive web applications. */}
              </h4>
            </Col>

            {/* //my-part */}

            <Col className="align-items-center" xs={14} md={6} xl={5}>
              <TrackVisibility>
                {({ isVisible }) => (
                  <div
                    className={
                      isVisible ? "animate__animated animate__zoomIn" : ""
                    }
                  >
                    <img
                      className="animatedImage"
                      src={bannerImage}
                      alt="Header Img"
                    />
                  </div>
                )}
              </TrackVisibility>
            </Col>
          </Row>
        </Container>
      </Container>

      {/* Social  */}

      <Home2 />
      <About />
      {/* <Education /> */}
      <Projects />
      <Contact id="contact" />
    </section>
  );
}

export default Home;
