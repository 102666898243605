import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import certidigital from "./certidigital.png";
import unit2 from "./unit2.png";
import unit3 from "./unit3.png";
import unit4 from "./unit4.png";
import searchBar from "./SearchBar.png";
import weatherApp from "./weatherApp.png";
import comingSoon from "./comingSoon.png";
import musicApp from "./MusicAPP.png";
import ModernArt from "./ModernArt.png";
import ProductPage from "./Product-page.png";
import BloodBank from "./BloodBank.png";
import TodoApp from "./TodoApp.png";
import Form_React from "./Form_React.png";
import Pexel_Search from "./Pexel_Search.png";
// import ModernArt from "./hello.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import { Flip, Slide, ToastContainer, Zoom, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillExperiment } from "react-icons/ai";
import { projectGif } from "../../encodedImages/projectGif";


function Projects() {
  const location = useLocation();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [projectCount, setProjectCount] = useState(0);
  // AOS : page transition
  useEffect(() => {
    AOS.init({ delay: 50 });
  }, []);

  useEffect(() => {
    if (location.pathname === "/projects") {
      const oneTime = setTimeout(() => {
        toast.info(
          <div>
            <AiFillExperiment className="iconToast" size={20} />
            <span className="fontToast">
              &nbsp;Discover more about the <span className="foo">project</span>{" "}
              by simply clicking on it{" "}
            </span>
          </div>,
          {
            position: isMobile ? "bottom-center" : "top-center",
            autoClose: 15000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            type: "default",
            icon: "🚀",
            transition: Slide,
          }
        );
      }, 500);

      // Clean up the interval when the component unmounts
      return () => {
        clearInterval(oneTime);
      };
    }
  }, [location.pathname]);

  // No. of projects
  useEffect(() => {
    setProjectCount(document.querySelectorAll(".project-card").length);
  }, []);
  console.log("@@@projectNumber", projectCount);

  //  Dummy Dat, change it with API call to artists
  const options = [
    {
      value: "HTML",
      label: "HTML",
      color: "#f4511e",
    },
    { value: "CSS", label: "CSS", color: "#f4511e" },
    { value: "JavaScript", label: "JavaScript", color: "#f4511e" },
    { value: "ReactJS", label: "ReactJS", color: "#f4511e" },
    { value: "Redux", label: "Redux", color: "#f4511e" },
    { value: "TypeScript", label: "TypeScript", color: "#f4511e" },
    { value: "Node", label: "Node", color: "#f4511e" },
    { value: "Express.js", label: "Express.js", color: "#f4511e" },
    { value: "MongoDB", label: "MongoDB", color: "#f4511e" },
    { value: "ChakraUI", label: "ChakraUI", color: "#f4511e" },
    { value: "Bootstrap", label: "Bootstrap", color: "#f4511e" },
    { value: "Ant Design", label: "Ant Design", color: "#f4511e" },
  ];

  const colorStyles = {
    menu: (provided) => ({
      ...provided,
      // width: "50%",
      overflowY: "scroll",
    }),
    control: (styles) => ({
      ...styles,
      margin: "auto",
      // width: "50%",
      backgroundColor: "white",
      marginLeft: "7px",
      marginRight: "7px",
    }),
    option: (styles, { data, isDisable, isFocused, isSelected }) => {
      //   console.log("option", data, isDisable, isFocused, isSelected);
      return { ...styles, color: data.color };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data.color,
        color: "#fff",
      };
    },
    multiValueLabel: (styles, { data }) => {
      return {
        ...styles,
        color: "#fff",
      };
    },
  };

  const loadOptions = (searchValue, callback) => {
    setTimeout(() => {
      const filteredOptions = options?.filter((ele) =>
        ele.label.toLowerCase().includes(searchValue.toLowerCase())
      );
      console.log("loadOptions", searchValue, filteredOptions);
      callback(filteredOptions);
    }, 2000);
  };

  const handleChange = (selectedOption) => {
    console.log("handleChange", selectedOption);
  };

  return (
    <Container fluid className="project-section" id="projects">
      <ToastContainer transition={Slide} />
      <Particle />

      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>

        <p style={{ color: "white" }}>
          Here are a few <strong className="purple">projects</strong> I've
          worked on in my <strong className="purple">coding journey.</strong>
        </p>
        <img
          style={{
            width: '100%',
            boxShadow: 'rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px'
          }}

          src={projectGif} alt="Your GIF" />

        <p className="ProjectCount">
          PROJECT COUNT : &nbsp;
          <strong className="purple">{projectCount} & counting..</strong>
        </p>

        {/* Select Tag */}
        {/* <AsyncSelect
          loadOptions={loadOptions}
          defaultOptions
          isMulti
          placeholder="Filter Projects..."
          onChange={handleChange}
          styles={colorStyles}
        /> */}

        {/* Select Tag */}

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Row className="project-card">
            <Col data-aos="zoom-in-right">
              <ProjectCard
                imgPath={ModernArt}
                isBlog={false}
                title="Modern&nbsp;  Art&nbsp;  Gallery"
                description="About
              This clone of the Marian Goodman Gallery website presents an extensive collection of artworks by renowned and influential artists"
                description1="Marian Goodman Gallery clone showcases influential artists' extensive art collection.
              "
                tech="React | Redux | React Router | ChakraUI | Node | Express| MongoDB | JWT | Bcrypt | Dummy Payment Process Implementation"
                ghLink="https://github.com/Sourav01112/ArtHub_Gallery"
                demoLink="https://arthub-mern-stack.souravcodes.in"
              />
            </Col>
          </Row>

          <Row className="project-card">
            <Col data-aos="zoom-in-left">
              <ProjectCard
                imgPath={BloodBank}
                isBlog={false}
                title="Blood&nbsp;  Bank"
                description="Blood Donation Center where Donors can see all their donations records and list of hospitals and organizations where they have donated blood and also the quantity. Hospitals can have the access to see how much quantity of blood they have consumed from different organizations with all the details"
                description1="Blood Donation Center: Donors view records, hospitals track blood consumption."
                tech="React | Redux Thunk | Redux Toolkit | React Router | Ant Design | Tailwind CSS | Node | MongoDB | Express| JWT | Bcrypt"
                ghLink="https://github.com/Sourav01112/Full-Stack-Blood-Bank-App"
                demoLink="https://lifeflowhub.souravcodes.in/"
              />
            </Col>
          </Row>

          <Row className="project-card">
            <Col data-aos="zoom-in-right">
              <ProjectCard
                imgPath={certidigital}
                isBlog={false}
                title="CertiDigital"
                description="Previously, Masai School used to create certificates for students' achievements manually with the help of Google Sheets which consumed a lot of time. In order to overcome this dependency on Google Sheets and reduce manual efforts, we created a website so that the Masai School team could create certificates in bulk and send them to their respective student’s email."
                description1="Automated certificate generation for Masai School saves time and effort."
                tech="React | Redux | React Router | Ant Design | Node | Express| MongoDB | JWT | Bcrypt "
                ghLink="https://github.com/masai-builds/Certidigital/tree/dev"
                demoLink="https://certidigital.netlify.app/"
              />
            </Col>
          </Row>
          <Row className="project-card">
            <Col data-aos="zoom-in-left">
              <ProjectCard
                imgPath={musicApp}
                isBlog={false}
                title="SaavnMusic"
                description="SaavnMusic is a music streaming service that provides international content. SaavnMusic is a digital musical service that allows people from around the world to access and experience music on a daily basis."
                description1="SaavnMusic: Global music streaming service for daily musical experiences."
                tech="React | Next | Tailwind CSS"
                ghLink="https://github.com/Sourav01112/MusicApp"
                demoLink="https://saavn-music.vercel.app/"
              />
            </Col>
          </Row>

        
          <Row className="project-card">
            <Col data-aos="zoom-in-right">
              <ProjectCard
                imgPath={unit4}
                isBlog={false}
                title="The &nbsp; Amazing &nbsp; Store"
                description="
              This React JS e-commerce website draws inspiration from the popular online store, Souled Store. It embraces the idea of pursuing passions and following your soul, as reflected in the brand's philosophy. The website incorporates essential e-commerce features, including product listings, shopping cart functionality, secure payment options, and user account management. Leveraging the power of React JS, this platform provides a contemporary and streamlined online shopping experience."
                description1="Streamlined e-commerce inspired by Souled Store, powered by React JS."
                tech="React | ChakraUI | JSON Server"
                ghLink="https://github.com/Sourav01112/fit-eggs-2634_"
                demoLink="https://souled-store-clone.vercel.app/"
              />
            </Col>
          </Row>

          <Row className="project-card">
            <Col data-aos="zoom-in-left">
              <ProjectCard
                imgPath={ProductPage}
                isBlog={false}
                title="Redux&nbsp;  Product&nbsp;  Page"
                description="
              This product page is built using React-Redux and leverages various functionalities like CRUD operations for Admin Side. It also includes several advanced features such as useSearchParams for search functionality, pagination for better navigation, filters for refined results, and sorting options for enhanced user experience and Login-Signup"
                description1="React-Redux product page with CRUD operations, search, pagination, filters, sorting, and authentication."
                tech="React | ChakraUI | Redux"
                ghLink="https://github.com/Sourav01112/Product-Page-using-React-Redux-CRUD-"
                demoLink="https://product-page-redux.vercel.app/"
              />
            </Col>
          </Row>

          <Row className="project-card">
            <Col data-aos="zoom-in-right">
              <ProjectCard
                imgPath={unit3}
                isBlog={false}
                title="Glitz&nbsp;  Global"
                description="This website serves as a replica of Crate and Barrel, a platform that carefully curates home inspirations by bridging the gap between the artistic creations of artisans and designers and individuals and locations worldwide.."
                description1="Replica of Crate and Barrel, bridging artisans and global inspirations."
                tech="HTML | CSS | JavaScript (Declarative - Way) | JSON Server"
                ghLink="https://github.com/Sourav01112/fumbling-throne-5724"
                demoLink="https://cozy-faun-2703e5.netlify.app/"
              />
            </Col>
          </Row>

          {/* Pexel - Search */}
          <Row className="project-card">
            <Col data-aos="zoom-in-left">
              <ProjectCard
                imgPath={Pexel_Search}
                isBlog={false}
                title="Pexel&nbsp; API&nbsp; Search"
                description="The Pexels API search with debouncing, routing, Redux for state management, Axios for fetching, single image detail page, and useSearchParams combines powerful technologies for a seamless user experience. With debouncing, search results load efficiently, while routing ensures smooth navigation. Redux handles state management, Axios fetches data, and useSearchParams preserves state and URL queries. Explore stunning images and dive into detailed image information with this robust application."
                description1="Efficient image search using Pexels API with debouncing for optimization."
                tech="React | Redux | API call"
                ghLink="https://github.com/Sourav01112/Pexel_Search"
                demoLink="https://pexel-search.vercel.app/"
              />
            </Col>
          </Row>

          <Row className="project-card">
            <Col data-aos="zoom-in-right">
              <ProjectCard
                imgPath={unit2}
                isBlog={false}
                title="The&nbsp;Bharat&nbsp;  Store"
                description="This clone is a derivative of Quikr, an Indian online marketplace and classified advertising company headquartered in Bangalore, India. Quikr operates across more than 1000 cities in India, offering listings in various categories including mobile phones, household goods, cars, real estate, jobs, services, and education."
                description1="Quikr-inspired online marketplace serving diverse categories across 1000+ Indian cities."
                tech="HTML | CSS | JavaScript"
                ghLink="https://github.com/Sourav01112/utmost-flower-8386"
                demoLink="https://clone-sourav-65a322.netlify.app/"
              />
            </Col>
          </Row>

          <Row className="project-card">
            <Col data-aos="zoom-in-left">
              <ProjectCard
                imgPath={searchBar}
                isBlog={false}
                title="React&nbsp;  Search &nbsp; Bar"
                description="This app features a SearchBar component, which is a functional component in React. It renders an input field designed for searching and retrieving data from an API. The component includes a search icon and enables real-time search capabilities that respond dynamically to the user's input."
                description1="React SearchBar component with real-time API data retrieval and dynamic search."
                tech="React | CSS"
                ghLink="https://github.com/Sourav01112/SerachBar-with-React-JS-with-API-calls-"
                demoLink="https://inspiring-cucurucho-06ad75.netlify.app/"
              />
            </Col>
          </Row>

          <Row className="project-card">
            <Col data-aos="zoom-in-right">
              <ProjectCard
                imgPath={weatherApp}
                isBlog={false}
                title="Weather&nbsp;App"
                description="I have developed a weather application using HTML, CSS, and JavaScript. This app is designed to be responsive and compatible with various devices, ensuring a seamless user experience. It provides real-time weather information whenever requested."
                description1="Responsive weather app with real-time information developed using HTML, CSS, JavaScript."
                tech="HTML | CSS | JavaScript"
                ghLink="https://github.com/Sourav01112/fit-eggs-2634_"
                demoLink="https://taupe-kheer-59238d.netlify.app/"
              />
            </Col>
          </Row>
          <Row className="project-card">
            <Col data-aos="zoom-in-left">
              <ProjectCard
                imgPath={TodoApp}
                isBlog={false}
                title="Todo&nbsp;App"
                description="Explore how to create a Todo App using React and utilize the localStorage functionality to ensure that the todos remain intact even if the page is refreshed or closed.."
                description1="Built a React Todo App with localStorage for data persistence."
                tech="React | CSS | JavaScript | Local Storage"
                ghLink="https://github.com/Sourav01112/Todo-App.git"
                demoLink="https://todo-app-ruddy-one.vercel.app/"
              />
            </Col>
          </Row>
          <Row className="project-card">
            <Col data-aos="zoom-in-right">
              <ProjectCard
                imgPath={Form_React}
                isBlog={false}
                title="Multiple&nbsp;Step&nbsp;React&nbsp;Form"
                description="The React Form with Multiple Steps project is a web application built using React and React Bootstrap. The primary goal of this project is to create a user-friendly and intuitive form that spans across multiple steps, allowing users to provide information in a structured manner."
                description1="React form with multi-step approach using React Bootstrap for efficiency."
                tech="React | React Bootstrap"
                ghLink="https://github.com/Sourav01112/Multiple-Step-React-Form"
                demoLink="https://multiple-step-react-form.vercel.app/"
              />
            </Col>
          </Row>

          <Row className="project-card">
            <Col data-aos="zoom-in-left">
              <ProjectCard
                imgPath={comingSoon}
                isBlog={false}
                title="Domegle"
                description="Clone of Omegle from Scratch"
                description1="Clone of Omegle from Scratch"
                tech="HTML | CSS | MongoDB | WebRTC | Socket.IO"
              // ghLink="https://github.com/Sourav01112/fumbling-throne-5724"
              // demoLink="https://cozy-faun-2703e5.netlify.app/"
              />
            </Col>
          </Row>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
